@font-face {
  font-family: "LeagueSpartan";
  src: url("./assets/fonts/LeagueSpartan-Bold.otf");
  font-weight: 400;
  font-style: normal;
}

main {
  --grey-color: #38606B, 
  --almond-color: #EBD3BC,
  --green-color: #103842
}

/* LINK FOR COLOR PALLETTE  https://coolors.co/38606b-ebd3bc-103842 */

html {
  background-color: #38606B;
}

.App {
  width: 100%;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #38606B;
  color: #EBD3BC;


}

.about-section-image-div {
  /* display: flex; */
  width: 40%;
  justify-content: center;
  align-items: center;
}

.about-section-image {
  /* height: 100%; */
  /* width: 100%; */
  /* max-height: 100%; */
  max-width: 100%;
  /* aspect-ratio: 1004/1025; */

}

.about-section-top-div {
  width: 80%;
  height: 40%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}
.about-section-bottom-div {
  /* height: 2000px; */
  /* padding-top: -500px; */
  width: 80%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.about-section-school-div {
  display: flex;
  width: 36%;
  /* background-color: blue; */
  /* align-items: center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border-top: 2px solid #EBD3BC;
}

.about-section-title {
  color: #EBD3BC;
}

.about-section-text {
  max-width: 100%;
  word-wrap: break-word;
  color: #EBD3BC;
}

.about-section-text-div {
  padding-top: -30%;
  /* flex: 1; */
  width: 40%;
  /* background-color: blue;  */
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.about-section-stanford-logo {
  height: 20em;

}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  line-height: 1.6;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  line-height: 1.2;
  margin: 0 0 1rem 0;
  font-family: "LeagueSpartan", sans-serif;
  border: none;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  margin: 0 0 1.5rem 0;
  border: none;
}

.contact-element-button {
  background-color: #38606B;
  width: 15%;
  /* height: 25%; */
  font-size: 1rem;
  color: #EBD3BC;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  padding: 1.75%;
  text-align: center;
  text-decoration: none;
}

.contact-elements-div {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

}

.contact-greater-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contact-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #EBD3BC;
  padding-bottom: 2.5%;
  color: #38606B;
}

.contact-section-title {
  color: #38606B;
}

.home-name-div {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section {
  background-image: url("./assets/images/palisades-sunset.jpg");
  height: 35rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #38606B;
}


.job-card {
  border: 2px solid #38606B;
  width: 33%;
}

.job-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-card-body-smaller {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-card-button {
  background-color: #38606B;
  width: 60%;
  /* height: 25%; */
  font-size: 1.5rem;
  color: #EBD3BC;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  padding: 2.5%;
}

.job-card-modal{
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-card-modal-box {
  /* position: 'absolute';
  top: 50%;
  left: 50%; */
  width: 75%;
  /* height: 75%; */
  min-height: 60%;
  /* transform: translate(-50%, -50%); */
  background-color: 'background.paper';
  border: 2px solid #000;
  background-color: black;
  text-align: center;
  color: white;
}

.job-card-modal-description {
  font-size: 1.5em;
  padding-left: 1%;
  padding-right: 1%;
}

.job-card-modal-list {
  text-align: left;
  font-size: 1.25em;
}

.resume-button {
  display: flex;
  background-color: #EBD3BC;
  width: 25%;
  height: 2.5em;
  font-size: 1.5rem;
  color: #38606B;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5%;
}

.nav-header-element {
  text-decoration: none;
  color: #EBD3BC;
  /* border: 2px solid #EBD3BC; */
  /* padding: 5px 10px 5px 10px;
  border-radius: 10px; */
  text-align: center;
  justify-content: center;
  align-items: center;
}

.projects-greater-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.project-card {
  border: 2px solid #EBD3BC;
}

.project-card-body {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5%;
}

.project-card-button {
  background-color: #EBD3BC;
  width: 60%;
  /* height: 25%; */
  font-size: 1.5rem;
  color: #38606B;
  border-radius: 15px;
  font-family: "LeagueSpartan", sans-serif;
  padding: 2.5%;
}

.project-card-description {
  text-align-last: center;
  text-align: center;
}

.project-card-title {
  text-align: center;
  font-size: 2rem;
}

.projects-grid-container {
  display: grid;
  width: 80%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10%; 
  grid-row-gap: 5em; 
  padding-bottom: 2.5%;
  
}

.projects-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #38606B;
  color: #EBD3BC;
}


.topRightNavButtonsDiv {
  background-color: green;
  width: 70%;
}

.topNameTextDiv {
  background-color: purple;
  width: 30%;
}

.typewriter-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.work-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #EBD3BC;
  padding-bottom: 2.5%;
  color: #38606B;
  text-align: center;
}

.work-experiences-div {
  display: flex;
  flex-direction: row;
  width: 60%;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.work-greater-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

}

:root {
  --primary-color: #2c3e50;
  --secondary-color: #3498db;
  --accent-color: #e74c3c;
  --text-color: #2c3e50;
  --background-color: #ffffff;
  --section-spacing: 4rem;
  --navbar-height: 64px;
}

.section {
  padding: var(--section-spacing) 0;
  width: 100%;
  border: none;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: var(--primary-color);
  text-align: center;
}

.section-subtitle {
  font-size: 1.25rem;
  color: var(--secondary-color);
  margin-bottom: 2rem;
  text-align: center;
}

/* Modern card styling */
.card {
  background: none;
  border: none;
  box-shadow: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

/* Button styling */
.button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  background-color: #EBD3BC;
  color: #38606B;
  text-decoration: none;
  font-family: "LeagueSpartan", sans-serif;
}

.button:hover {
  background-color: #d4b89d;
  transform: translateY(-2px);
}

/* Grid layout */
.grid {
  display: grid;
  gap: 2rem;
  border: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section {
    padding: 3rem 0;
  }

  .section-title {
    font-size: 2rem;
  }

  .container {
    padding: 0 1rem;
  }

  #home .grid {
    grid-template-columns: 1fr !important;
    text-align: center;
  }

  #home .button-container {
    justify-content: center;
  }
}

/* Animation classes */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.6s ease forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2980b9;
}

main {
  padding-top: var(--navbar-height); /* Add padding to prevent content from hiding under navbar */
}

#home {
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  align-items: center;
  background-color: #38606B;
  position: relative;
  color: #EBD3BC;
  font-family: "LeagueSpartan", sans-serif;
  overflow: hidden;
}

#home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/images/palisades-sunset-min.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.15;
  z-index: 1;
}

#home .container {
  position: relative;
  z-index: 2;
  width: 100%;
  border: none;
  outline: none;
}

#home .grid {
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
}

#home h1 {
  color: #EBD3BC;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin: 0;
}

#home p, #home div {
  color: #EBD3BC;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.button {
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  background-color: #EBD3BC;
  color: #38606B;
  text-decoration: none;
  font-family: "LeagueSpartan", sans-serif;
  font-size: 1.1rem;
}

.button:hover {
  background-color: #d4b89d;
  transform: translateY(-2px);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
  border: none;
}

/* Remove any potential borders from grid items */
.grid > * {
  border: none;
}